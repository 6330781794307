import React from "react"
import { VideoType } from "../../../../@core/types/domain/video/VideoType"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faComments,
  faEye,
  faMedal,
  faShareAlt,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import styled from "@emotion/styled"
import { Stylable } from "../../../../types/reactTypes"
import { formatDuration, formatNumber } from "../../../../utils/formatUtils"
import { EMPTY_STRING } from "../../../../utils/jsUtils"

interface VideoSocialStatsProps extends Stylable {
  video: VideoType
  showLength?: boolean
}

function VideoSocialStats({
  className,
  video,
  showLength,
}: VideoSocialStatsProps) {
  return (
    <Container className={className}>
      <Number icon={faMedal} title={"OC-Score"} value={video.points} />
      <Number icon={faEye} title={"Views"} value={video.views} />
      <Number icon={faThumbsUp} title={"Likes"} value={video.likeCount} />
      <Number icon={faComments} title={"Comments"} value={video.commentCount} />
      <Number icon={faShareAlt} title={"Shares"} value={video.shareCount} />
      {showLength && (
        <Property
          icon={faClock}
          title={"Duration"}
          value={formatDuration(video.length)}
        />
      )}
    </Container>
  )
}

interface PropertyProps<T = string> {
  icon: IconProp
  value: T
  title?: string
}

function Number(props: PropertyProps<number>) {
  return (
    <Property
      icon={props.icon}
      value={formatNumber(props.value) || EMPTY_STRING}
      title={props.title}
    />
  )
}

function Property(props: PropertyProps) {
  return (
    <span title={props.title}>
      <FontAwesomeIcon icon={props.icon} />
      {props.value}
    </span>
  )
}

const Container = styled.div`
  display: flex;
  white-space: nowrap;
  text-align: left;
  svg {
    margin-right: 4px;
  }
  span {
    //flex-shrink: 1;
    //flex-grow: 1;
    margin-right: 12px;
  }
`

export default VideoSocialStats
