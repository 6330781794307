import { fetchDetectionApi } from "@core/service/detection/fetchDetectionApi"
import { toJson, withTimeout } from "@core/utils/fetchUtils"
import { TaskInfo } from "@core/types/detection/DetectionTypes"
import {
  activeDetectionServers,
  DetectionServerInfo,
} from "@core/types/detection/DetectionServer"
import { requireOk } from "~/services/fetchApi"
import { roundN } from "~/utils/js/mathUtils"
import { ensureDate } from "~/utils/dateUtils"

export function fetchDetectionTasks(): Promise<TaskInfo[]> {
  return Promise.all(
    activeDetectionServers.map((server) =>
      fetchDetectionTasksForServer(server).catch((err) => {
        console.error(`failed contacting ${server.name}:`, err)
        return null
      })
    )
  ).then((results) => {
    const successResults = results.filter((r) => !!r)
    return [].concat(...successResults)
  })
}

function fetchDetectionTasksForServer(server: DetectionServerInfo) {
  return withTimeout(
    7500,
    fetchDetectionApi("/tasks", undefined, server)
      .then(requireOk)
      .then(toJson)
      .then((json) => {
        if (!json) return []
        return Object.entries(json).map(([key, json]) => {
          return mapJson(key, json, server)
        })
      })
  )
}

function mapJson(id: string, json: any, server: DetectionServerInfo): TaskInfo {
  const { video_pos, video_length } = json.status
  const progress =
    video_pos && video_length
      ? roundN((video_pos / video_length) * 100, 2)
      : undefined
  return {
    id,
    status: json.status?.status,
    statusText: json.status?.message,
    webrtcUrl: json?.return?.webrtc_url,
    totalLength: video_length,
    progress,
    progressPos: json.status?.video_pos,
    server,
    request: json.request,
    createdAt: ensureDate(json.status.created),
  }
}
