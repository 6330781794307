import React, { useState } from "react"
import { faSearchDollar } from "@fortawesome/free-solid-svg-icons"
import { fetchQueueVideoDetection } from "../../../../services/detection/detectionService"
import { alertError, alertInfo } from "../../../../utils/alert/alertUtils"
import IconButton from "../../../../components/button/IconButton"

interface RequestDetectionButtonProps {
  videoId: number
}

export function RequestDetectionButton({
  videoId,
}: RequestDetectionButtonProps) {
  const [loading, setLoading] = useState(false)
  return (
    <IconButton
      icon={faSearchDollar}
      variant={"is-danger"}
      rounded
      disabled={loading}
      onClick={() => {
        setLoading(true)
        fetchQueueVideoDetection(videoId)
          .then(() => {
            alertInfo("Detection has been requested")
          })
          .catch((reason) => {
            alertError(reason.toString())
          })
          .then(() => {
            setLoading(false)
          })
      }}
    >
      Static Detection
    </IconButton>
  )
}
