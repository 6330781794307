import {
  defaultDetectionServer,
  DetectionServerInfo,
} from "@core/types/detection/DetectionServer"

export function fetchDetectionApi(
  endpoint: string,
  args?: RequestInit,
  server: DetectionServerInfo = defaultDetectionServer
) {
  return fetch(server.url + endpoint, {
    ...args,
    headers: {
      "Content-Type": args?.method === "POST" ? "application/json" : undefined,
      ...args?.headers,
    },
  })
}
