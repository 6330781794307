import { fetchApi, requireOk, toJson } from "../fetchApi"
import {
  sortableVideoFields,
  VideoType,
  VideoUpdate,
} from "../../@core/types/domain/video/VideoType"
import { VideoFilterType } from "../../views/videos/filter/types/VideoFilterType"
import { queryParams } from "../../utils/jsUtils"
import { DateFilterType } from "../../views/videos/filter/types/DateFilterType"
import { ensureDate } from "../../utils/dateUtils"
import { IdsFilterType } from "../../views/videos/filter/types/IdsFilterType"
import { TextFilterType } from "../../views/videos/filter/types/TextFilterType"
import { QueryVideosResult } from "../../@core/types/api/videoRouteTypes"

export interface FetchVideoOptions {
  filter?: VideoFilterType
  page?: number
  pageSize?: number
  orderBy?: sortableVideoFields
  orderByReverse?: boolean
}

export function fetchVideos(
  options: FetchVideoOptions
): Promise<QueryVideosResult> {
  const query = queryParams({
    page: options.page,
    pageSize: options.pageSize,
    orderBy: options.orderBy,
    orderByReverse: options.orderByReverse,
    ...filterToQuery(options.filter),
  })
  return fetchApi(`/video?${query}`)
    .then(toJson)
    .then((json: QueryVideosResult) => {
      return (
        json && {
          ...json,
          results: json.results.map(parseResult),
        }
      )
    })
}

export function fetchVideo(videoId: number): Promise<VideoType> {
  return fetchApi(`/video/${videoId}`).then(toJson).then(parseResult)
}

export function fetchPublicVideo(videoId: number): Promise<VideoType> {
  return fetchApi(`/public/video/${videoId}`, {
    headers: {
      Authorization: "PUBLIC",
    },
  })
    .then(requireOk)
    .then(toJson)
    .then(parseResult)
}

export function updateVideo(
  videoId: number,
  update: VideoUpdate
): Promise<VideoType> {
  return fetchApi(`/video/${videoId}`, {
    method: "PATCH",
    body: JSON.stringify(update),
  })
    .then(requireOk)
    .then(toJson)
    .then(parseResult)
}

function parseResult(json: VideoType) {
  json.publishAt = ensureDate(json.publishAt)
  return json
}

function filterToQuery(filter: VideoFilterType): {} {
  const query: any = {}
  if (filter.project && filter.project.in) {
    query["projectId"] = filter.project.in[0]
  }
  if (filter.publishAt) {
    addDateParam(filter.publishAt, query, "publishAt")
  }

  query["status"] = idsParam(filter.status)
  query["customStatus"] = idsParam(filter.customStatus)
  query["category"] = idsParam(filter.category)
  query["platformId"] = idsParam(filter.platform)
  query["eventTag"] = idsParam(filter.eventTag)
  query["hasComments"] = filter.hasComments ? true : null
  query["hasNote"] = filter.hasNote ? true : null
  query["ebuMember"] = filter.isEbuMember ? true : null

  query["title"] = textParam(filter.title)
  query["channelName"] = textParam(filter.channelName)

  return query
}

function textParam(filter: TextFilterType) {
  if (!filter || (filter.type !== "contains" && !filter.search)) return null
  return `%${filter.search}%`
}

function idsParam(filter: IdsFilterType) {
  if (!filter) return null
  if (filter.in && filter.in.length > 0) {
    return filter.in.join(",")
  } else if (filter.notIn && filter.notIn.length > 0) {
    return `!${filter.notIn.join(",")}`
  } else return null
}

function addDateParam(filter: DateFilterType, args: object, name: string) {
  switch (filter.type) {
    case "between":
      args[`${name}_min`] = stripTime(filter.from)
      args[`${name}_max`] = stripTime(filter.to)
      break
    case "exact":
      args[`${name}`] = stripTime(filter.date)
      break
    case "from":
      args[`${name}_min`] = stripTime(filter.from)
      break
    case "to":
      args[`${name}_max`] = stripTime(filter.to)
      break
  }
}

/**
 * extracts the date prat from date
 */
function stripTime(value) {
  const date = ensureDate(value)
  return date && date.toISOString().split("T")[0]
}
