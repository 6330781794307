import React from "react"
import { Link } from "gatsby"
import { GatsbyLinkProps } from "gatsby-link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import classNames from "classnames"
import styled from "@emotion/styled"
import { WithChildren } from "~/types/reactTypes"
import { ButtonVariant } from "~/components/button/Button"

interface LinkButtonProps
  extends WithChildren,
    Pick<GatsbyLinkProps<any>, "target"> {
  url: string
  icon?: IconProp
  title?: string
  variant: ButtonVariant
  loading?: boolean
  rounded?: boolean
  small?: boolean
  className?: string
}

function LinkButton(props: LinkButtonProps) {
  return (
    <Link
      to={props.url}
      target={props.target}
      className={classNames("button", props.variant, props.className, {
        "is-loading": props.loading,
        "is-rounded": props.rounded,
        "is-small": props.small,
      })}
      title={props.title}
    >
      {props.icon && (
        <Icon
          icon={props.icon}
          fixedWidth
          className={classNames({
            "has-text": !!props.children,
          })}
        />
      )}
      {props.children}
    </Link>
  )
}

const Icon = styled(FontAwesomeIcon)`
  &.has-text {
    margin-right: 6px;
  }
`

export default LinkButton
