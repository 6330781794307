import { VideoType } from "../../@core/types/domain/video/VideoType"
import { Platform } from "../../@core/types/domain/Platform"

export function getChannelUrl(video: VideoType) {
  switch (video.platformId) {
    case Platform.YouTube:
      return `https://www.youtube.com/channel/${video.channelId}`
    case Platform.Facebook:
      return video.channelId && `https://facebook.com/${video.channelId}`
    case Platform.Instagram:
      return (
        video.channelName && `https://www.instagram.com/${video.channelName}`
      )
    case Platform.TikTok:
      return `https://www.tiktok.com/${video.channelName}`
    default:
      return null
  }
}
