import { Reducer, useMemo, useReducer } from "react"

export type FetchState<T> = {
  data?: T
  isLoading: boolean
  error?: Error
}

type Action<T> =
  | { type: "request" }
  | { type: "success"; results: T }
  | { type: "failure"; error: Error }

function reducer<T>(state: FetchState<T>, action: Action<T>): FetchState<T> {
  switch (action.type) {
    case "request":
      return { isLoading: true, data: null, error: null }
    case "success":
      return { isLoading: false, data: action.results, error: null }
    case "failure":
      return { isLoading: false, error: action.error, data: null }
  }
}

export function useFetchState<T>(): [FetchState<T>, Dispatches<T>] {
  // @ts-ignore
  const [state, dispatch] = useReducer<Reducer<FetchState<T>, Action<T>>>(
    reducer,
    { isLoading: false }
  )
  const dispatches = useMemo(() => createDispatches(dispatch), [])
  return [state, dispatches]
}

export interface Dispatches<T> {
  setLoading: () => void
  onSuccess: (results: T) => void
  onFail: (error: Error) => void
}

function createDispatches<T>(dispatch) {
  return {
    setLoading: () => dispatch({ type: "request" }),
    onSuccess: (results: T) => dispatch({ type: "success", results }),
    onFail: (error: Error) => dispatch({ type: "failure", error }),
  }
}
